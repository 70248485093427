<template>
  <div class="add-side">
    <button v-if="!showForm" @click="showForm = true">Add Sides</button>
    <form v-if="showForm" @submit.prevent="handleSubmit">
      <h4>Add a Side to the Course</h4>
      <input
        type="text"
        placeholder="Side Name (eg: Front 9, Back 9)"
        required
        v-model="sideName"
      />
      <input
        type="text"
        placeholder="Tee Color (eg: White, Blue, etc)"
        required
        v-model="teeColor"
      />
      <input type="number" placeholder="Par" required v-model="par" />
      <input type="text" placeholder="Rating" required v-model="rating" />
      <input type="text" placeholder="Slope" required v-model="slope" />
      <input
        type="number"
        placeholder="Number of holes"
        required
        v-model="numHoles"
      />
      <input
        type="number"
        placeholder="Total Yardage"
        required
        v-model="yardage"
      />
      <button>Add</button>
    </form>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useDocument from "@/composables/useDocument";

export default {
  props: ["course"],
  setup(props) {
    const sideName = ref("");
    const teeColor = ref("");
    const par = ref("");
    const rating = ref("");
    const slope = ref("");
    const numHoles = ref("");
    const yardage = ref("");
    const showForm = ref(false);
    const { updateDoc } = useDocument("golfcourses", props.course.id);

    const handleSubmit = async () => {
      const newSide = {
        sideName: sideName.value,
        teeColor: teeColor.value,
        par: par.value,
        rating: rating.value,
        slope: slope.value,
        numHoles: numHoles.value,
        yardage: yardage.value,
        id: Math.floor(Math.random() * 1000000),
      };

      await updateDoc({
        sides: [...props.course.sides, newSide],
      });
      sideName.value = "";
      teeColor.value = "";
      par.value = "";
      rating.value = "";
      slope.value = "";
      numHoles.value = "";
      yardage.value = "";
    };

    return {
      sideName,
      teeColor,
      par,
      rating,
      slope,
      numHoles,
      yardage,
      showForm,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
.add-side {
  text-align: center;
  margin-top: 40px;
}
form {
  max-width: 100%;
  text-align: left;
}
</style>
