import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/auth/Login.vue";
import Signup from "../views/auth/Signup.vue";
import CreateCourse from "../views/courses/CreateCourse.vue";
import CourseDetails from "../views/courses/CourseDetails.vue";
import UserCourses from "../views/courses/UserCourses.vue";

// route guard
import { projectAuth } from "../firebase/config";

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: requireAuth,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/courses/create",
    name: "CreateCourse",
    component: CreateCourse,
    beforeEnter: requireAuth,
  },
  {
    path: "/courses/:id",
    name: "CourseDetails",
    component: CourseDetails,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/courses/user",
    name: "UserCourses",
    component: UserCourses,
    beforeEnter: requireAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
