<template>
  <div class="user-courses">
    <h2>My Courses</h2>
    <div v-if="courses">
      <ListView :courses="courses" />
    </div>
    <router-link :to="{ name: 'CreateCourse' }" class="btn"
      >Create a new course</router-link
    >
  </div>
</template>

<script>
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";
import ListView from "@/components/ListView.vue";

export default {
  components: { ListView },
  setup() {
    const { user } = getUser();
    const { documents: courses } = getCollection("golfcourses", [
      "userId",
      "==",
      user.value.uid,
    ]);

    console.log(courses);
    return { courses };
  },
};
</script>

<style></style>
