<template>
  <form @submit.prevent="handleSubmit">
    <h4>Create New Course</h4>
    <input type="text" required placeholder="Course Name" v-model="name" />
    <input type="text" placeholder="Address 1" v-model="address1" />
    <input type="text" placeholder="Address 2" v-model="address2" />
    <input type="text" placeholder="City" v-model="city" />
    <input type="text" placeholder="State" v-model="state" />
    <input type="text" placeholder="Zip" v-model="zip" />
    <input type="url" placeholder="URL" v-model="courseUrl" />
    <!-- upload course image -->
    <label>Upload Course Image</label>
    <input type="file" @change="handleChange" />
    <div class="error">{{ fileError }}</div>

    <div class="error">{{ error }}</div>
    <button v-if="!isPending">Create</button>
    <button v-else disabled>Saving...</button>
  </form>
</template>

<script>
import { ref } from "@vue/reactivity";
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";

export default {
  setup() {
    const { filePath, url, uploadImage } = useStorage("courses");
    const { error, addDoc } = useCollection("golfcourses");
    const { user } = getUser();
    const router = useRouter();

    const name = ref("");
    const address1 = ref("");
    const address2 = ref("");
    const city = ref("");
    const state = ref("");
    const zip = ref("");
    const file = ref(null);
    const fileError = ref(null);
    const courseUrl = ref(null);
    const isPending = ref(false);

    const handleSubmit = async () => {
      if (file.value) {
        isPending.value = true;
        await uploadImage(file.value);
        const res = await addDoc({
          name: name.value,
          address1: address1.value,
          address2: address2.value,
          city: city.value,
          state: state.value,
          zip: zip.value,
          userId: user.value.uid,
          userName: user.value.displayName,
          courseImageUrl: url.value,
          filePath: filePath.value,
          courseUrl: courseUrl.value,
          sides: [],
          createdAt: timestamp(),
        });
        isPending.value = false;
        if (!error.value) {
          router.push({ name: "CourseDetails", params: { id: res.id } });
        }
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpg", "image/jpeg"];
    const handleChange = (e) => {
      const selected = e.target.files[0];

      console.log(selected);

      if (selected && types.includes(selected.type)) {
        file.value = selected;
        fileError.value = null;
      } else {
        file.value = null;
        fileError.value = "Please select an image file (png or jpg)";
      }
    };

    return {
      name,
      address1,
      address2,
      city,
      state,
      zip,
      handleSubmit,
      handleChange,
      fileError,
      courseUrl,
      isPending,
    };
  },
};
</script>

<style>
input[type="file"] {
  border: 0;
  padding: 0;
}
label {
  font-size: 12px;
  display: block;
  margin-top: 30px;
}
button {
  margin-top: 20px;
}
</style>
