<template>
  <div class="error" v-if="error">{{ error }}</div>
  <div class="course-details" v-if="course">
    <!-- Course Information -->
    <div class="course-info">
      <div class="cover">
        <img :src="course.courseImageUrl" />
      </div>

      <h2>{{ course.name }}</h2>
      <p>Created by {{ course.userName }}</p>
      <p>{{ course.address1 }}</p>
      <p>{{ course.city }}, {{ course.state }} {{ course.zip }}</p>
      <!-- <a href="https://course.courseURL">{{ course.courseUrl }}</a> -->
      <button v-if="ownership" @click="handleDelete">Delete Course</button>
    </div>

    <!-- side list -->
    <div class="side-list">
      <div v-if="!course.sides.length">
        No sides have been added to this Course yet
      </div>
      <div class="single-side" v-for="side in course.sides" :key="side.Id">
        <div class="details">
          <h3>{{ side.sideName }}</h3>
          <p>
            TeeColor: {{ side.teeColor }} Par: {{ side.par }} Rating:
            {{ side.rating }} Slope: {{ side.slope }} Number of Holes:
            {{ side.numHoles }} Yardage: {{ side.yardage }}
          </p>
        </div>
        <button @click="handleClick(side.id)" v-if="ownership">Delete</button>
      </div>
      <AddSide v-if="ownership" :course="course" />
    </div>
  </div>
</template>

<script>
import getDocument from "@/composables/getDocument";
import getUser from "@/composables/getUser";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import AddSide from "@/components/AddSide";
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default {
  props: ["id"],
  components: { AddSide },
  setup(props) {
    const { error, document: course } = getDocument("golfcourses", props.id);
    const { user } = getUser();
    const { deleteDoc, updateDoc } = useDocument("golfcourses", props.id);
    const { deleteImage } = useStorage();
    const router = useRouter();

    const ownership = computed(() => {
      return (
        course.value && user.value && user.value.uid == course.value.userId
      );
    });

    const handleDelete = async () => {
      await deleteImage(course.value.filePath);
      await deleteDoc();
      router.push({ name: "Home" });
    };

    const handleClick = async (id) => {
      const sides = course.value.sides.filter((side) => side.id != id);
      await updateDoc({ sides });
    };

    return { error, course, ownership, handleDelete, handleClick };
  },
};
</script>

<style>
.course-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 80px;
}
.cover {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  padding: 160px;
}
.cover img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 200%;
  max-height: 200%;
}
.course-info {
  text-align: center;
}
.course-info h2 {
  text-transform: capitalize;
  font-size: 28px;
  margin-top: 20px;
}
.course-info p {
  margin-bottom: 20px;
}
.username {
  color: #999;
}
</style>
