<template>
  <div class="home">
    <div class="error" v-if="error">{{ error }}</div>
    <div v-if="documents">
      <ListView :courses="documents" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ListView from "../components/ListView.vue";
import getCollection from "../composables/getCollection";
export default {
  name: "Home",
  components: { ListView },
  setup() {
    const { error, documents } = getCollection("golfcourses");

    return { error, documents };
  },
};
</script>
