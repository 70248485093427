// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCxgIhbwel3L8dUPNDqmtpKuY6Ik5kHLqE",
  authDomain: "awestats-f5397.firebaseapp.com",
  projectId: "awestats-f5397",
  storageBucket: "awestats-f5397.appspot.com",
  messagingSenderId: "789237176826",
  appId: "1:789237176826:web:9529c2f3095f00bf5eac43",
  measurementId: "G-DGTSSVR2P4",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, projectAuth, projectStorage, timestamp };
