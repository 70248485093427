<template>
  <div v-for="course in courses" :key="course.id">
    <router-link :to="{ name: 'CourseDetails', params: { id: course.id } }">
      <div class="single">
        <div class="thumbnail">
          <img :src="course.courseImageUrl" />
        </div>
        <div class="info">
          <h3>{{ course.name }} - {{ course.city }}, {{ course.state }}</h3>
          <p>Created by {{ course.userName }}</p>
        </div>
        <div class="side-number">
          <p>{{ course.sides.length }}</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["courses"],
};
</script>

<style scoped>
.single {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
.thumbnail {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
  border-radius: 10px;
}
img {
  max-width: 150%;
  max-height: 150%;
  display: block;
}
.info {
  margin: 0 30px;
}
.side-number {
  margin-left: auto;
}
</style>
